import * as React from "react"

import { Animated } from "react-animated-css"
import * as Icon from "react-feather"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"
import FAQ from "../components/faq"

const IndexPage = () => (
  <Layout>
    <Seo title="Solpaneler" />
    <Header search={true} />

    <section className="content">
      <div className="container">
        <div className="row header-text">
          <div className="col">
            <Animated animationIn="fadeInDown" isVisible={true} children={
              <h2>Vi jobbar i fyra enkla steg</h2>
            } />
          </div>
        </div>
        <div className="row text-center">
          <div className="col-12 col-lg-3">
            <Animated animationIn="fadeInRight" animationInDelay={0} isVisible={true} children={<>
              <div className="icon-holder">
                <Icon.PlusSquare className="icon" />
              </div>
              <div className="step-text">
                <h3>1. Räkna</h3>
                <p>Antingen gör du det själv med vårt beräkningsverktyg,eller så kommer vi hem till dig på ett kostnadsfritt besökoch gör det åt dig.</p>
              </div>
            </>} />
          </div>

          <div className="col-12 mt-5 mt-lg-0 col-lg-3">
            <Animated animationIn="fadeInLeft" animationInDelay={1} isVisible={true} children={<>
              <div className="icon-holder">
                <Icon.PenTool className="icon" />
              </div>
              <div className="step-text">
                <h3>2. Rita</h3>
                <p>När vi vet hur dina förutsättningar ser ut ritar vi upp en anläggning som passar dig och ditt tak.</p>
              </div>
            </>} />
          </div>

          <div className="col-12 mt-5 mt-lg-0 col-lg-3">
            <Animated animationIn="fadeInRight" animationInDelay={2} isVisible={true} children={<>
              <div className="icon-holder">
                <Icon.Home className="icon" />
              </div>
              <div className="step-text">
                <h3>3. Visa</h3>
                <p>När ritningarna är klara kommer vi hem till dig och presenterar dem tillsammans med en offert.</p>
              </div>
            </>} />
          </div>

          <div className="col-12 mt-5 mt-lg-0 col-lg-3">
            <Animated animationIn="fadeInLeft" animationInDelay={3} isVisible={true} children={<>
              <div className="icon-holder">
                <Icon.Smile className="icon" />
              </div>
              <div className="step-text">
                <h3>4. Installera</h3>
                <p>Mellan 6-8 veckor efter beställning kommer vi och installerar din solcellsanläggning. Det brukar ta 2-4 dagar, beroende på storlek.</p>
              </div>
            </>} />
          </div>
        </div>
      </div>
    </section>

    <section className="content">
      <div className="container">
        <div className="row header-text">
          <div className="col"><h2>Fördelar med våra solpaneler</h2></div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <img src="https://cellsolar.se/wp-content/uploads/2021/04/web-img-12.png" alt="" className="img-fluid" />
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center">
            <div className="text">
              <h2>Solpaneler med hela 520 W!</h2>
              <p>Våra senaste solpaneler är kraftfullare än någonsin. Hela 520 Watt. Få så hög avkastning av din investering som möjligt och producera el generationer framöver. Kontakta oss för att höra mer och få ett kostnadsfritt förslag.</p>
              <a href="#0" className="btn btn-primary">Boka möte</a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-6 d-flex align-items-center">
            <div className="text">
              <h2>Solceller som räknar med moln</h2>
              <p>Våra solpaneler är uppkopplade till ett så kallat optimerat system. I klarspråk betyder det att en elektronisk komponent kallad optimerare har fästs på solpanelerna för att du ska producera så mycket el som möjligt, även när solen går i moln.</p>
              <a href="#0" className="btn btn-light">Läs mer</a>
            </div>
          </div>
          <div className="col-12 col-lg-6 order-first order-lg-last">
            <img src="https://cellsolar.se/wp-content/uploads/2020/01/Final_Render-CROPPED.jpg" alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </section>

    <section className="offer">
      <div className="container">
        <div className="row header-text">
          <div className="col">
            <h2>Fyll i formuläret så hör vi av oss</h2>
          </div>
        </div>

        <div className="form-row">
          <div className="col">
            <strong>Vad kan vi hjälpa dig med?</strong>
            <div className="flex mt-2">
              <input type="checkbox" id="first" /> <label for="first">Solceller</label>
            </div>
            <div className="flex mt-2">
              <input type="checkbox" id="second" /> <label for="second">Laddboxar</label>
            </div>
            <div className="flex mt-2">
              <input type="checkbox" id="third" /> <label for="third">Övrigt</label>
            </div>
            <div className="flex mt-2">
              <input type="checkbox" id="fourth" /> <label for="fourth">Information om grönt bolån</label>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <label className="mb-1">För- och efternamn</label>
            <input type="text" name="" className="form-control" />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-6">
            <label className="mb-1">E-mail</label>
            <input type="text" name="" className="form-control" />
          </div>

          <div className="col-lg-6">
            <label className="mb-1">Telefon</label>
            <input type="text" name="" className="form-control" />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-4">
            <label className="mb-1">Adress</label>
            <input type="text" name="" className="form-control" />
          </div>

          <div className="col-lg-4">
            <label className="mb-1">Postnr.</label>
            <input type="text" name="" className="form-control" />
          </div>

          <div className="col-lg-4">
            <label className="mb-1">Ort</label>
            <input type="text" name="" className="form-control" />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <label className="mb-1">Övrig information</label>
            <textarea className="form-control" rows={5} placeholder="Något annat vi behöver veta?"></textarea>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col text-right d-flex justify-content-end">
            <button type="submit" className="btn btn-light">Kontakta oss</button>
          </div>
        </div>
      </div>
    </section>

    <FAQ />
  </Layout>
)

export default IndexPage
