import React from 'react';

import * as Icon from "react-feather"

const FAQ = () => {
    const [activeFaq, setActiveFaq] = React.useState('');

    const faq = [
        {
            question: "Vilken sorts solpaneler använder ni?",
            answer: "Du kan känna dig trygg med att solpanelerna vi erbjuder kommer från de främsta producenterna, har hög kvalitet, bra garantier och är tillverkade med fokus på hållbarhet. Vi kräver dokumentation från producenterna på att kvaliteten är hög och att arbetsvillkoren på fabrikerna är tillfredsställande."
        },
        {
            question: "Hur snabbt kan solcellerna vara på plats?",
            answer: "Solcellerna brukar vara på plats inom 4–8 veckor. Så här går det till: (1) Vi bestämmer tid för monteringen med dig. (2) Våra tekniker monterar panelerna under 1–2 dagar. Hur lång tid det tar beror på hur stort taket är, vilka säkerhetsanordningar som behövs och vilken sorts taktäckning du har. (3) En elektriker kopplar in anläggningen till husets elcentral och startar anläggningen. (4) Ditt nätföretag monterar en ny elmätare och registrerar elen som du ska ha betalt för."
        },
        {
            question: "Vad händer med solcellspanelerna när det snöar?",
            answer: "Snön glider lättare av solcellerna än på ett vanligt tak, eftersom ovansidan är av glas. Snön smälter också fortare tack vare solcellernas mörka färg. Vi har koll på snöbelastningen för ditt område och bygger solceller som tål svenskt väder. Därför behöver du inte skotta bort snön."
        },
        {
            question: "Solceller blir bättre för varje år. Borde jag vänta?",
            answer: "Solcellstekniken förbättras hela tiden, samtidigt som den är både stabil och hållbar. En tumregel är att den blir en procent bättre per år. Därför lönar det sig att köpa nu och planera för att ha solcellerna länge. En av de stora fördelarna med solcellspaneler är att det är en stabil och varaktig teknik. Effektgarantin från fabriken är på 25 år eller mer. En anläggning kan ofta producera el efter 30–40 år. Det betyder att när det är dags att byta ut anläggningen, kan du se fram emot en ännu högre elproduktion."
        },
        {
            question: "Är det lönsamt med solpaneler?",
            answer: "Solelen har ett fast, lågt pris som inte varierar under årets gång. När solcellerna producerar mycket el på sommaren sparar du pengar. Sparslantarna får du ut på vintern när solcellerna inte producerar lika mycket."
        }
    ];

    return (
        <section className="faq">
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <h3>Vanliga frågor & svar</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="faq-links">
                            {faq.map((f, index) => 
                            <div className="faq-link">
                                <div className="question" onClick={() => activeFaq === index ? setActiveFaq('') : setActiveFaq(index)}>
                                    <h4 children={f.question} />
                                    {activeFaq === index ? <Icon.ChevronDown /> : <Icon.ChevronRight />}
                                </div>
                                <div className={activeFaq === index ? "answer" : "answer d-none"}>
                                    <p children={f.answer} />
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        <a href="#0" className="btn btn-light">Ring oss på 08 12 34 56</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FAQ;